export default {
  headers: [
    {
      id: 'mainHeader',
      logo: {
        alt: 'dana and jeff logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/bisevo/logo-white.webp',
        url: 'https://danaandjeffluxuryhomes.com/',
      },
      breakpoint: 999999,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: '',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: '',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: '',
          imageClassName: 'on-bisevo-header-image',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: 'on-test',
          content: 'Open',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-bisevo-header',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: 'on-bisevo-header-item',
        menuClassName: 'on-bisevo-header-menu',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-bisevo-header-menu-item',
        subMenuClassName: 'on-bisevo-header-submenu',
        subMenuItemClassName: 'on-bisevo-header-submenu-item',
        headerItems: [
          {
            id: 'h-our-properties-item',
            content: 'OUR PROPERTIES',
            url: 'https://danaandjeffluxuryhomes.com/properties/sale',
            className: 'on-bisevo-desktop-menu-item',
          },
          {
            id: 'h-meet-team-item',
            content: 'MEET THE TEAM',
            url: 'https://danaandjeffluxuryhomes.com/about',
            className: 'on-bisevo-desktop-menu-item',
          },
          {
            id: 'h-mls-search-item',
            content: 'MLS-SEARCH',
            url: 'https://danaandjeffluxuryhomes.com/home-search/listings',
            className: 'on-bisevo-desktop-menu-item',
          },
          {
            id: 'h-contact-us-item',
            content: 'CONTACT US',
            url: 'mailto:contact@danaandjeffestates.com',
            className: 'on-bisevo-desktop-menu-item',
          },
          {
            id: 'h-phone-item',
            content: '(747) 888-0508',
            url: 'tel:7478880508',
            className: 'on-bisevo-desktop-menu-item',
          },
        ],
        items: [
          {
            id: 'home-menu-item',
            content: 'HOME',
            url: 'https://danaandjeffluxuryhomes.com/',
          },
          {
            id: 'meet-team-menu-item',
            content: 'MEET THE TEAM',
            url: 'https://danaandjeffluxuryhomes.com/about',
          },
          {
            id: 'properties-menu-item',
            content: 'PROPERTIES',
            menuItems: [
              {
                id: 'geatured-menu-item',
                content: 'Featured Listings',
                url: 'https://danaandjeffluxuryhomes.com/properties/sale',
              },
              {
                id: 'sold-menu-item',
                content: 'Sold Listings',
                url: 'https://danaandjeffluxuryhomes.com/properties/sold',
              },
            ],
          },
          {
            id: 'worldwide-menu-item',
            content: 'WORLDWIDE',
            url: 'https://danaandjeffluxuryhomes.com/properties/worldwide',
            className: 'on-bisevo-header-item-worldwide',
          },
          {
            id: 'home-search-menu-item',
            content: 'HOME SEARCH',
            url: 'https://danaandjeffluxuryhomes.com/home-search/listings',
          },
          {
            id: 'refferal-menu-item',
            content: 'EXCLUSIVE REFERRAL GROUP',
            url: 'https://danaandjeffluxuryhomes.com/exclusive-referral-group',
          },
          {
            id: 'listing-advantage-item',
            content: 'FRONTGATE REAL ESTATE LISTING ADVANTAGE',
            url: 'https://www.danaandjeffestates.com/coming-soon-listings/',
          },
          {
            id: 'buy-before-you-sell-item',
            content: 'BUY BEFORE YOU SELL',
            url: 'https://trade-in.danaandjeffluxuryhomes.com/',
          },
          {
            id: 'become-an-all-acsh-buyer-item',
            content: 'BECOME AN ALL-CASH BUYER',
            url: 'https://cashoffer.danaandjeffluxuryhomes.com/',
          },
          {
            id: 'blog-item',
            content: 'BLOG',
            url: 'https://danaandjeffluxuryhomes.com/blog',
          },
          {
            id: 'communities-item',
            content: 'COMMUNITIES',
            url: 'https://danaandjeffluxuryhomes.com/neighborhoods',
          },
          {
            id: 'testimonials-item',
            content: 'TESTIMONIALS',
            url: 'https://danaandjeffluxuryhomes.com/testimonials',
          },
          {
            id: 'contact-us-item',
            content: 'CONTACT US',
            url: 'mailto:contact@danaandjeffestates.com',
          },
          {
            id: 'my-search-portal-item',
            content: 'MY SEARCH PORTAL',
            url: 'https://danaandjeffluxuryhomes.com/home-search/account',
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-bisevo-footer-first',
      display: true,
      className: 'on-bisevo-footer-section-first',
      sectionClassName: 'on-bisevo-footer-section-container',
      columns: [
        {
          id: 'col-one',
          className: 'on-bisevo-footer-column align-center col-xl-4 col-lg-4 col-md-4 col-sm-24 mb-3 pb-1',
          style: {},
          items: [
            {
              id: 'col-one-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'JEFF BIEBUYCK & DANA OLMES',
            },
            {
              id: 'col-one-menu',
              type: 'menu',
              className: 'on-bisevo-footer-menu',
              itemClassName: 'mb-0',
              style: {},
              items: [
                {
                  id: 'col-one-item-one',
                  data: 'Frontgate Real Estate',
                },
                {
                  id: 'col-one-item-two',
                  data: 'Premier Real Estate Agents',
                },
                {
                  id: 'col-one-item-three',
                  data: 'in Calabasas & Hidden Hills',
                },
                {
                  id: 'col-one-item-four',
                  data: 'Dana Olmes | CA DRE# 00944676',
                },
                {
                  id: 'col-one-item-five',
                  data: 'Jeff Biebuyck | CA DRE# 01383921',
                },
              ],
            },
          ],
        },
        {
          id: 'col-two',
          className: 'on-bisevo-footer-column align-center col-xl-4 col-lg-4 col-md-4 col-sm-24 mb-3 pb-1',
          style: {},
          items: [
            {
              id: 'col-two-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'LOCATION',
            },
            {
              id: 'col-two-menu',
              type: 'menu',
              className: 'on-bisevo-footer-menu',
              itemClassName: 'mb-0',
              style: {},
              items: [
                {
                  id: 'col-two-item-one',
                  data: 'Get Directions',
                  url: 'https://www.google.com/maps/search/?api=1&query=23500%20Long%20Valley%20Rd,%20Hidden%20Hills,%20CA%2091302,%20USA',
                },
              ],
            },
          ],
        },
        {
          id: 'col-three',
          className: 'on-bisevo-footer-column align-center col-xl-4 col-lg-4 col-md-4 col-sm-24 mb-3 pb-1',
          style: {},
          items: [
            {
              id: 'col-three-heading',
              type: 'heading',
              elementType: 'h1',
              className: '',
              style: {},
              data: 'CONTACT US',
            },
            {
              id: 'col-three-menu',
              type: 'menu',
              className: 'on-bisevo-footer-menu',
              itemClassName: 'mb-0',
              style: {},
              items: [
                {
                  id: 'col-three-item-one',
                  data: '(747) 888-0508',
                  url: 'tel:(747) 888-0508',
                  className: 'on-bisevo-footer-phone',
                },
                {
                  id: 'col-three-item-two',
                  data: '23500 Long Valley Rd, Hidden Hills, CA',
                },
                {
                  id: 'col-three-item-three',
                  data: '91302, USA',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-bisevo-footer-second',
      display: true,
      className: 'on-bisevo-footer-section',
      sectionClassName: 'on-bisevo-footer-section-container',
      columns: [
        {
          id: 'logo-column',
          className: 'on-bisevo-footer-disclaimer align-center pt-3',
          style: {},
          items: [
            {
              id: 'bisevo-logo',
              type: 'image',
              className: 'pt-1',
              url: 'https://si-homelight.s3.amazonaws.com/sites/bisevo/header-logo-white.webp',
              style: { width: '250px' },
            },
            {
              id: 'footer-disclaimer',
              type: 'itemContent',
              className: 'pt-2 pb-2 mb-0',
              style: {},
              items: [
                {
                  content:
                    'While some of the listings on this site may not be our exclusive listings, we have ongoing relationships with all of the listing agents. View our ',
                },
                {
                  content: 'Disclaimer.',
                  url: 'https://danaandjeffluxuryhomes.com/disclaimer',
                  className: 'on-disclaimer-link',
                },
              ],
            },
            {
              id: 'bisevo-logo',
              type: 'image',
              className: 'on-bisevo-footer-realtor-logo pt-2 pb-2',
              url: 'https://si-homelight.s3.amazonaws.com/sites/bisevo/footer-logo-equal-white.webp',
              style: {},
            },
            {
              id: 'footer-copyright',
              type: 'itemContent',
              className: 'pb-2 mb-0',
              style: { fontSize: '20px' },
              items: [
                {
                  content: 'Website designed and developed by ',
                },
                {
                  content: 'Luxury Presence.',
                  url: 'https://www.luxurypresence.com/',
                  className: 'on-luxury-link',
                },
              ],
            },
            {
              id: 'footer-socials',
              type: 'socialIcons',
              className: '',
              itemClassName: 'on-bisevo-footer-socials-item',
              style: {},
              items: [
                {
                  id: 'bisevo-youtube',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'youtube',
                  url: 'https://www.youtube.com/channel/UCyi2N_0tV7gu3AM0Vm-aQxA',
                },
                {
                  id: 'bisevo-instagram',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'instagram',
                  url: 'https://www.instagram.com/danaandjeff/',
                },
                {
                  id: 'bisevo-facebook',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook',
                  url: 'https://www.facebook.com/DanaAndJeff//',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-bisevo-footer-third',
      display: true,
      className: 'on-bisevo-footer-section-last align-center',
      sectionClassName: 'on-bisevo-footer-section-container-last',
      columns: [
        {
          id: 'copyright-column',
          className: 'on-bisevo-footer-copyright align-left col-xl-6 col-sm-12 col-md-6',
          style: {},
          items: [
            {
              type: 'itemContent',
              className: 'mb-0 pt-sm-4 pt-md-0',
              style: {},
              items: [
                {
                  content: 'COPYRIGHT © 2022  ',
                },
                {
                  content: ' |',
                },
                {
                  content: 'POLICY',
                  url: 'https://danaandjeffluxuryhomes.com/terms-and-conditions',
                  className: 'on-privacy-link',
                },
              ],
            },
          ],
        },
        {
          id: 'names-column',
          className: 'on-bisevo-footer-copyright align-right col-xl-6 col-sm-12 col-md-6',
          style: {},
          items: [
            {
              id: 'footer-2022',
              type: 'heading',
              className: '',
              data: 'JEFF BIEBUYCK & DANA OLMES',
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {
    'tradeIn.hero.className': 'heroClassName',
    'cashOffer.hero.className': 'heroClassName',
    'tradeIn.howItWorks.className': 'howItWorksClassName',
    'cashOffer.howItWorks.className': 'howItWorksClassName',
  },
  siteData: {
    heroClassName: 'on-bisevo-hero',
    howItWorksClassName: 'on-bisevo-how-it-works',
    agency: 'Frontgate Real Estate ',
    agentDescription: 'Top agents in the Greater Los Angeles Area',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/bisevo/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/bisevo/hero-bg.jpg',
  },
};
